<template>
  <div v-if="user && token" class="followed-businesses-container">
    <section class="trending-section coupons-section">
      <div class="coupons-container">
        <h2>Favorite Businesses</h2>
        <div v-if="businesses.length === 0" class="no-businesses">
          You don't currently have any favorite businesses.
        </div>
        <div v-else class="businesses-list">
          <div
            v-for="(business) in displayedBusinesses"
            :key="business._id"
            @click="viewBusinessProfile(business._id)"
            class="business-card"
          >
            <img :src="business.business_logo" alt="Business Logo" class="business-logo" />
            <h3>{{ business.business_name }}</h3>
            <button @click="viewBusinessProfile(business._id)">View Business Profile</button>
          </div>
        </div>
        <div v-if="businesses.length > displayedBusinesses.length" class="load-more-container">
          <button @click="loadMore" class="load-more-button">Load More</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'FollowedBusinesses',
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      token: localStorage.getItem('token'),
      businesses: [],
      displayedBusinesses: [],
      itemsToShow: 4,
    };
  },
  created() {
    if (this.user && this.token) {
      this.fetchFollowedBusinesses();
    }
  },
  methods: {
    async fetchFollowedBusinesses() {
      if (this.user && this.user.id && this.token) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/businesses/followed`, {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
            params: {
              userId: this.user.id,
            },
          });
          this.businesses = response.data.businesses;
          this.displayedBusinesses = this.businesses.slice(0, this.itemsToShow);
        } catch (error) {
          console.error('Error fetching followed businesses:', error);
        }
      }
    },
    viewBusinessProfile(businessId) {
      window.scrollTo(0, 0);
      this.$router.push(`/business/${businessId}`);
    },
    loadMore() {
      const nextItems = this.displayedBusinesses.length + this.itemsToShow;
      this.displayedBusinesses = this.businesses.slice(0, nextItems);
    },
  },
};
</script>

<style scoped>
.followed-businesses-container {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

h2{
  color: white;
}

/* Styles for the gray background container */
.trending-section {
  margin-bottom: 30px;
}

.coupons-section .coupons-container {
  border-radius: 15px;
  border: solid 1px #4C6B30;
  padding: 20px;
}

.coupons-container h2 {
  text-align: left;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
}

/* Existing styles */
.no-businesses {
  text-align: center;
  color: #777;
}

.businesses-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.business-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  width: 220px;
  max-width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 350px;
}

.business-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.business-logo {
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 10px;
  display: block;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
}

button:hover {
  background-color: #6E9F49;
}

button.load-more-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  margin-top: 20px;
}

button.load-more-button:hover {
  background-color: #6E9F49;
}

.load-more-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .business-card {
    width: 70%;
    max-width: none;
    margin: 10px 0;
    padding: 15px;
    height: auto; /* Adjust height to auto */
  }

  .business-logo {
    max-width: 100%;
  }

  button {
    padding: 8px 15px;
    font-size: 14px;
  }

  .coupons-container h2 {
    margin-left: 5%;
  }
}

@media (max-width: 576px) {
  .businesses-list {
    flex-direction: column;
    align-items: center;
  }

  .business-card {
    margin: 0 auto 20px;
    width: calc(100% - 40px);
    height: auto; /* Adjust height to auto */
  }
}
</style>
