<template>
  <div class="gift-card" @click="navigateToGiftCardPage">
    <div class="gift-card-image">
      <img :src="cardImageSrc" alt="Gift Card Image" />
    </div>
    <div class="gift-card-content">
      <div class="business-title-row">
        <div class="business-name">{{ businessName}}</div>
        <div class="rating-wrapper">
          <span class="star">★</span>
          <span class="rating-number">{{ rating }}</span>
        </div>
      </div>
      <div class="bussiness-address">{{ businessAddress }}</div>
      <h3 class="deal-name">{{ dealTitle }}</h3>
      <div class="price-wrapper">
        <span class="original-price">${{ originalPrice }}</span>
        <span class="current-price">${{ calculateDiscountedPrice(originalPrice, discountPercentage) }}</span>
        <span class="discount">{{ discountPercentage.toFixed(2) }}% OFF</span>
      </div>
      <div v-if="showButton" class="button-wrapper">
        <button @click="handleButtonClick">{{ buttonText }}</button>
        <div v-if="errorMessage[0]" class="error-message">{{ errorMessage[0] }}</div>
      </div>
      <div v-if="isLowStock" class="low-stock-warning">
        <span class="hot-icon">🔥</span>
        <span>Almost out of Stock</span>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImage from '@/assets/default-image.png';
import axios from 'axios';

export default {
  name: 'GiftCard',
  props: {
    giftCardId: {
      type: String,
      required: true
    },
    businessName: {
      type: String,
      required: true
    },
    dealTitle: {
      type: String,
      required: true
    },
    rating: {
      type: Number,
      required: true
    },
    cardImage: {
      type: String,
      default: ''
    },
    originalPrice: {
      type: Number,
      required: true
    },
    discountPercentage: {
      type: Array,
      required: true
    },
    businessId: {
      type: String,
      required: true
    },
    businessAddress: {
      type: String,
      default: ''
    },
    optionsCount: {
      type: Number,
      default: 1
    },
    numberinstock: {
      type: Number,
      default: 999
    }
  },
  data() {
    return {
      isInCart: false,
      message: '',
      fading: false,
      isLoggedIn: false,
      token: localStorage.getItem('token'),
      showButton: this.shouldShowButton(),
      optionQuantities: {},
      cartStatuses: {},
      alreadyPurchasedMessage: {},
      errorMessage: {},
    };
  },
  computed: {
    cardImageSrc() {
      return Array.isArray(this.cardImage) ? this.cardImage[0] : this.cardImage || defaultImage;
    },
    buttonText() {
      if (this.isInCart) {
        return 'View Cart';
      }
      return this.optionsCount > 1 ? 'View More Options' : 'Add to Cart';
    },
    stockCount() {
      // Handle both cases: when numberinstock is an array or a single value
      return Array.isArray(this.numberinstock) ? this.numberinstock[0] : this.numberinstock;
    },
    isLowStock() {
      return this.stockCount < 10 && this.stockCount > 0;
    }
  },
  methods: {
    navigateToGiftCardPage() {
      this.$router.push(`/gift-card/${this.giftCardId}`).then(() => {
        window.location.reload();
        window.scrollTo(0, 0);
      });
    },
    calculateDiscountedPrice(originalPrice, discountPercentage) {
      return (originalPrice * (1 - discountPercentage / 100)).toFixed(2);
    },
    getStarClass(index) {
      if (this.rating === 0) {
        return 'blank';
      } else if (index <= Math.floor(this.rating)) {
        return 'filled';
      } else if (index === Math.ceil(this.rating) && this.rating % 1 !== 0) {
        return 'half-filled';
      } else {
        return 'blank';
      }
    },
    async handleButtonClick(event) {
      event.stopPropagation();
      if (this.isInCart) {
        this.$router.push('/cart');
      } else if (this.optionsCount > 1) {
        this.$router.push(`/gift-card/${this.giftCardId}`);
        window.location.reload();
      } else {
        const success = await this.addToCart(0);
        if (success) {
          this.$router.push('/cart');
        } else {
          this.showMessage('You have already purchased this gift card.');
        }
      }
    },
    async addToCart(optionId) {
      if (this.cartStatuses[optionId]) {
        this.showMessage('This option is already in the cart');
        return false;
      } else {
        return await this.saveGiftCard(optionId);
      }
    },
    async saveGiftCard(optionId) {
      const cartItemIdentifier = `${this.giftCardId}-${optionId}`;
      let checkoutCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];

      if (checkoutCartItems.some(item => item.cartItemId === cartItemIdentifier)) {
        this.showMessage('This option is already in the cart');
        return false;
      }

      const quantity = this.optionQuantities[optionId] || 1;
      const newItem = {
        cartItemId: cartItemIdentifier,
        giftCardId: this.giftCardId,
        optionId: optionId,
        dealTitle: this.dealTitle,
        originalPrice: this.originalPrice,
        discount: this.discountPercentage,
        quantity: quantity,
        businessId: this.businessId,
        name: this.dealTitle,
        image_link: this.cardImageSrc,
        businessName: this.businessName,
        businessAddress: this.businessAddress,
        rating: this.rating
      };

      if (this.isLoggedIn) {
        const userId = JSON.parse(localStorage.getItem('user')).id;
        const token = localStorage.getItem('token');
        
        try {
          console.log("Adding to cart with businessId:", this.businessId); // Add logging for debugging
          await axios.post(`${process.env.VUE_APP_API_URL}/api/shoppingCart/saveCart/${userId}`, newItem, {
              headers: { 'Authorization': `Bearer ${token}` }
            });

          checkoutCartItems.push(newItem);
          localStorage.setItem('checkoutCartItems', JSON.stringify(checkoutCartItems));
          window.dispatchEvent(new CustomEvent('update-checkout-cart', { detail: checkoutCartItems }));
          this.cartStatuses[optionId] = true;
          return true;

        } catch (error) {
          console.error("Axios Error:", error);
          console.error("Failed cart item data:", newItem); // Log the failed item data

          if (error.response && error.response.status === 400) {
            this.errorMessage = { ...this.errorMessage, [optionId]: 'You have already purchased this gift card and cannot buy another one.' };
          } else {
            this.errorMessage = { ...this.errorMessage, [optionId]: 'Sorry, an error occurred while adding to cart.' };
          }

          setTimeout(() => {
            this.errorMessage = { ...this.errorMessage, [optionId]: '' };
          }, 3000);

          return false;
        }
      } else {
        checkoutCartItems.push(newItem);
        localStorage.setItem('checkoutCartItems', JSON.stringify(checkoutCartItems));
        window.dispatchEvent(new CustomEvent('update-checkout-cart', { detail: checkoutCartItems }));
        this.cartStatuses[optionId] = true;
        return true;
      }
    },
    async removeFromCart(optionId) {
      const user = JSON.parse(localStorage.getItem('user'));
      const userId = user ? user.id : null;
      const cartItemIdentifier = `${this.giftCardId}-${optionId}`;

      if (userId) {
        try {
          await axios.put(`${process.env.VUE_APP_API_URL}/api/shoppingCart/remove-from-cart/${userId}`, {
            giftCardId: this.giftCardId,
            name: this.dealTitle
          }, {
            headers: { 'Authorization': `Bearer ${this.token}` }
          });
        } catch (error) {
          console.error('Error removing from cart on server:', error);
        }
      }

      let checkoutCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];
      checkoutCartItems = checkoutCartItems.filter(item => item.cartItemId !== cartItemIdentifier);
      localStorage.setItem('checkoutCartItems', JSON.stringify(checkoutCartItems));
      window.dispatchEvent(new CustomEvent('update-checkout-cart', { detail: checkoutCartItems }));
      this.showMessage('Gift Card Removed from Cart');
      this.cartStatuses[optionId] = false;
    },
    showMessage(text) {
      this.message = text;
      this.fading = false;
      setTimeout(() => {
        this.fading = true;
        setTimeout(() => {
          this.message = '';
          this.fading = false;
        }, 1000);
      }, 3000);
    },
    shouldShowButton() {
      let abGroup = localStorage.getItem('abTestGroup');

      if (!abGroup) {
        // Get user ID if logged in
        const user = JSON.parse(localStorage.getItem('user'));
        let userId;
        
        if (user && user.id) {
          userId = user.id.toString();
        } else {
          // For non-logged in users, get or create a session ID
          userId = localStorage.getItem('sessionToken');
          if (!userId) {
            const array = new Uint32Array(1);
            window.crypto.getRandomValues(array);
            userId = array[0].toString();
            localStorage.setItem('sessionToken', userId);
          }
        }

        // Create a simple hash from the userId string
        let hash = 0;
        for (let i = 0; i < userId.length; i++) {
          hash = ((hash << 5) - hash) + userId.charCodeAt(i);
          hash = hash & hash; // Convert to 32bit integer
        }

        // Determine group A or B based on hash
        abGroup = (Math.abs(hash) % 2 === 0) ? 'A' : 'B';
        localStorage.setItem('abTestGroup', abGroup);
      }

      return abGroup === 'A';
    },
    checkIfInCart() {
      const checkoutCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];
      this.isInCart = checkoutCartItems.some(item => item.giftCardId === this.giftCardId);
    }
  },
  async mounted() {
    if (this.options) {
      this.options.forEach(option => {
        this.optionQuantities[option.id] = 1;
      });
    }
    window.addEventListener('update-checkout-cart', this.checkIfInCart);
    const user = JSON.parse(localStorage.getItem('user'));
    this.isLoggedIn = !!(user && user.id);
    if (user && user.notification_preferences && user.notification_preferences[this.businessId]) {
      const preferences = user.notification_preferences[this.businessId];
      this.isFan = !!preferences.isFan;
      this.subscription = {
        email: preferences.email || false,
        text: preferences.text || false,
        notifications: preferences.notifications || false
      };
    }
    this.checkIfInCart();
  },
  beforeUnmount() {
    window.removeEventListener('update-checkout-cart', this.checkIfInCart);
  },
}
</script>

<style scoped>
.gift-card {
  border-radius: 8px;
  background: white;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  flex: 1 0 calc(25% - 10px);
  margin: 5px;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  padding-bottom: 10px;
}

.gift-card-image {
  width: 100%;
  background: #f5f5f5;
  position: relative;
  padding-top: 56.25%;
}

.gift-card-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gift-card-content {
  padding: 12px;
}

.business-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.business-name {
  font-size: 17px;
  color: #333333;
  font-weight: 600;
  padding-top: 1%;
  font-family: 'Roboto', sans-serif;
  padding-bottom: 5px;
}

.bussiness-address{
  font-size: 15px;
  color: #505050;
  font-weight: 600;
  margin-bottom: 12px;
  font-family: 'Roboto', sans-serif;
}

.rating-wrapper {
  display: flex;
  align-items: center;
  gap: 2px;
}

.star {
  color: #FFD700;
  font-size: 25px;
}

.rating-number {
  font-size: 15px;
  color: #333;
  font-weight: 600;
  padding-top:9%;
  padding-left: 4%
}

.deal-name {
  margin: 0 0 12px 0;
  font-size: 30px;
  line-height: 1.3;
  color: #333333;
  font-weight: 800;
  font-family: 'Roboto', sans-serif;
  padding-bottom: 15px;
}

.price-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.original-price {
  color: #666;
  text-decoration: line-through;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}

.current-price {
  color: #2E8B57;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}

.discount {
  background-color: #E8F5E9;
  color: #2E8B57;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}

.button-wrapper {
  text-align: center;
  margin-top: 10px;
}

.error-message {
  color: red;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.button-wrapper button {
  background-color: #2E8B57;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}

.button-wrapper button:hover {
  background-color: #276a46;
}

.low-stock-warning {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d32f2f;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-top: 12px;
  text-transform: uppercase;
  border-top: 1px dashed #d32f2f;
  border-bottom: 1px dashed #d32f2f;
  padding: 6px 0;
}

.hot-icon {
  margin-right: 6px;
  font-size: 14px;
}

@media (max-width: 1200px) {
  .gift-card {
    flex: 1 0 calc(33.333% - 10px);
  }
}

@media (max-width: 992px) {
  .gift-card {
    flex: 1 0 calc(50% - 10px);
  }
}

@media (max-width: 768px) {
  .gift-card {
    flex: 1 0 calc(100% - 10px);
    max-width: none;
    width: 90%;
    margin: 5px auto;
    margin-bottom: 20px;
  }

  .gift-card-image {
    height: auto;
  }
}
</style>
